import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './auth/index.js'
import main from './main/index.js'
import program from './program/index.js'
import ourteam from './ourteam/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: authModule,
    main,
    program,
    ourteam
  }
})