import Vue from 'vue'
import VueRouter from 'vue-router'
import main from './components/main/main.vue'
import login from './components/login/login.vue'
import emailVerification from './components/email-verification/email-verification.vue'
// import dashboard from './components/main/dashboard/dashboard.vue'
import program from './components/main/program/program.vue'
import bookedProgram from './components/main/program/booked/booked.vue'
import createProgram from './components/main/program/create-program/create-program.vue'
// import event from './components/main/program/event/event.vue'
// import masterclass from './components/main/program/masterclass/masterclass.vue'
// import registeredProgram from './components/main/registered-program/registered-program.vue'
import transaction from './components/main/transaction/transaction.vue'
import migrateUsers from './components/main/migrate-users/migrate-users.vue'
import temanHannah from './components/main/teman-hannah/teman-hannah.vue'
import teamHannah from './components/main/team-hannah/team-hannah.vue'
import message from './components/main/message/message.vue'
import store from './store/index.js'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    name: 'main',
    path: '/',
    redirect: '/program',
    component: main,
    beforeEnter: ifAuthenticated,
    children: [
      // {
      //   name: 'dashboard',
      //   path: '/dashboard',
      //   component: dashboard,
      // },
      // {
      //   name: 'program',
      //   path: '/program',
      //   redirect: '/program/event',
      //   component: program,
      //   children: [
      //     {
      //       name: 'event',
      //       path: 'event',
      //       component: event,
      //     },
      //     {
      //       name: 'masterclass',
      //       path: 'masterclass',
      //       component: masterclass,
      //     },
      //     {
      //       name: 'create-program',
      //       path: 'create',
      //       component: createProgram,
      //     },
      //     {
      //       name: 'edit-program',
      //       path: 'edit/:programId?',
      //       component: createProgram,
      //     },
      //   ]
      // },
      {
        name: 'program',
        path: '/program',
        component: program,
      },
      {
        name: 'create-program',
        path: '/program/create',
        component: createProgram,
      },
      {
        name: 'edit-program',
        path: '/program/edit/:programId?',
        component: createProgram,
      },
      {
        name: 'booked-program',
        path: '/program/booked/:programId',
        component: bookedProgram,
      },
      {
        path: '/transaction',
        component: transaction,
      },
      {
        name: 'migrateUsers',
        path: '/migrate-users',
        component: migrateUsers,
      },
      {
        name: 'temanHannah',
        path: '/teman-hannah',
        component: temanHannah,
      },
      {
        name: 'teamHannah',
        path: '/tim-hannah',
        component: teamHannah,
      },
      {
        name: 'message',
        path: '/message',
        component: message,
      },
    ]
  },
  {
    name: 'login',
    path: '/login',
    component: login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    name: 'email-verification',
    path: '/email-verification/:activationCode',
    component: emailVerification,
  }
]

const router = new VueRouter({ mode: 'history', routes })

export default router