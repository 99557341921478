import { SidebarMenu } from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import './main.css'

export default {
  name: 'sidebar',
  components: {
    SidebarMenu
  },
  data() {
    return {
      menu: [
        // {
        //   href: '/dashboard',
        //   title: 'Dashboard',
        //   icon: 'fas fa-columns'
        // },
        // {
        //   href: '/program',
        //   title: 'Program',
        //   icon: 'fa fa-calendar',
        //   child: [
        //     {
        //       href: '/program/create',
        //       title: 'Buat Program',
        //       icon: 'fa fa-plus'
        //     },
        //     {
        //       href: '/program/event',
        //       title: 'Event'
        //     },
        //     {
        //       href: '/program/masterclass',
        //       title: 'Masterclass'
        //     },
        //   ]
        // },
        {
          href: '/program',
          title: 'Program',
          icon: 'fa fa-calendar',
        },
        // {
        //   href: '/registered-program',
        //   title: 'Registered Program',
        //   icon: 'fa fa-list'
        // },
        {
          href: '/transaction',
          title: 'Transaction',
          icon: 'fa fa-credit-card'
        },
        // {
        //   href: '/migrate-users',
        //   title: 'Migrasi ke Teman Hannah',
        //   icon: 'fa fa-compress-alt'
        // },
        {
          href: '/teman-hannah',
          title: 'Teman Hannah',
          icon: 'fa fa-users'
        },
        {
          href: '/tim-hannah',
          title: 'Tim Hannah',
          icon: 'fa fa-sitemap'
        },
        {
          href: '/message',
          title: 'Message',
          icon: 'fa fa-envelope'
        },
      ],
      collapsed: false,
      isOnMobile: false,
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize())
  },
  methods: {
    onToggleCollapse(collapsed) {
      console.log(collapsed)
      this.collapsed = collapsed
    },
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
        this.collapsed = true
      } else {
        this.isOnMobile = false
        this.collapsed = false
      }
    },
    onItemClick(event, item, node) {
      console.log(event);
      console.log(item);
      console.log(node);
    },
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    }
  }
}