export default {
  name: 'teman-hannah',
  data() {
    return {
      isLoading: false,
      users: [],
      fields: [
        { key: 'id', label: 'User ID', sortable: true, class: 'align-center' },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phoneNumber', label: 'Phone Number', sortable: true },
        { key: 'age', label: 'age', sortable: true },
        { key: 'domicile', label: 'Domicile', sortable: true },
        { key: 'isActive', label: 'Status', sortable: true },
        { key: 'createdAt', label: 'Created At', sortable: true },
        { key: 'actions', label: 'Actions' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 100],
      search: null,
    }
  },
  created() {
    this.$store.commit('main/setNavbarTitle', 'Teman Hannah')
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.$http.get('/users', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((res) => {
        this.users = res.data.data.map((e) => {
          if (e.marital_status === "LAJANG") {
            e.marital_status = "Lajang"
            e.num_of_children = "-"
            e.child_age = "-"
          } else if (e.marital_status === 'MENIKAH') {
            e.marital_status = "Sudah Menikah"
            const childAge = []
            JSON.parse(e.child_age).forEach((age) => {
              childAge.push(age)
            })
            e.child_age = childAge.join(', ')
          }

          if (e.domicile === null) e.domicile = "-"
          if (e.marital_status === null) e.marital_status = "-"
          if (e.num_of_children === null) e.num_of_children = "-"
          if (e.child_age === null) e.child_age = "-"

          if (e.isActive === 0) e.isActive = "Belum Aktif"
          else e.isActive = "Aktif"
          return e
        })

        this.totalRows = this.users.length
      }).catch((err) => {
        if (err.response) {
          const statusCode = err.response.status
          const message = err.response.data.message
          if (statusCode !== 404) this.$toast.error(message)
        } else this.$toast.error('Network Error')
      })
    },
    resendEmailVerification(email) {
      this.isLoading = true
      const formData = new FormData()
      formData.append('email', email)

      this.$http.post('/users/resend-email-verification', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.isLoading = false
        this.$toast.success('Email verifikasi terkirim')
      }).catch(err => {
        this.isLoading = false
        const message = err.response.data.message
        this.$toast.error(message)
      })
    },
    activateUser(id) {
      this.isLoading = true
      this.$http.put(`/users/activate/${id}`, null, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        this.isLoading = false
        this.$toast.success('Activate success')
        const index = this.users.findIndex(user => user.id === id);
        this.users[index].isActive = "Aktif"
      }).catch(err => {
        this.isLoading = false
        const message = err.response.data.message
        this.$toast.error(message)
      })
    },
    exportToExcel() {
      this.$http.get('/users/export', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((res) => {
        const filename = res.data.data.filename
        this.$http.get(`/admins/file/${filename}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          responseType: 'blob',
        }).then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();

          this.$swal.fire({
            title: 'Success',
            icon: 'success',
            text: 'Export success',
          })
        })
      }).catch(() => {
        this.$swal.fire({
          title: 'Failed',
          icon: 'error',
          text: 'Export failed',
        })
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}