import axios from '../../remote/axios/index.js'

export default {
  getPrograms({ commit }, type) {
    commit('setProgramUIState', {
      action: 'GETTING',
      status: 'LOADING'
    })
    let endPoint = '/program'
    if (type) endPoint += `?type=${type}`
    axios.get(endPoint).then((response) => {
      const data = response.data.data
      commit('setEvents', data)
      commit('setProgramUIState', {
        action: 'GETTING',
        status: 'SUCCESS'
      })
    }).catch((err) => {
      commit('setEvents', [])
      let message = 'Network error'
      let statusCode = 500
      if (err.response) {
        statusCode = err.response.status
        if (statusCode !== 404) message = err.response.data.message
      }
      commit('setProgramUIState', {
        action: 'GETTING',
        status: 'FAILED',
        code: statusCode,
        message
      })
    })
  },
  getProgramById({ commit }, programId) {
    axios.get(`/program/${programId}`).then((response) => {
      const data = response.data.data
      commit('setProgramUIState', {
        action: 'GETTING',
        status: 'SUCCESS',
        data
      })
    }).catch((err) => {
      const message = err.response.data.message
      commit('setProgramUIState', {
        action: 'GETTING',
        status: 'FAILED',
        message
      })
    })
  },
  createProgram({ commit }, program) {
    const formData = new FormData()
    formData.append('name', program.name.value)
    formData.append('date', program.date.value)
    formData.append('location', program.location)
    formData.append('needs', JSON.stringify(program.needs))
    formData.append('description', program.description.value)
    formData.append('type', program.type)
    formData.append('limited', program.limited)
    formData.append('isListed', program.isListed ? 1 : 0)
    formData.append('withMerchandise', program.withMerchandise)
    formData.append('price', parseInt(program.price, 10))
    formData.append('shippingCost', parseInt(program.shippingCost, 10))
    if (program.poster !== null) formData.append('poster', program.poster)
    if (!program.eventAddress.isEmpty) formData.append('eventAddress', program.eventAddress.value)
    if (!program.telconLink.isEmpty) formData.append('telconLink', program.telconLink.value)
    if (program.wagLink !== null) formData.append('wagLink', program.wagLink)

    commit('setProgramUIState', {
      action: 'CREATE',
      status: 'LOADING'
    })
    axios.post('/program', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(() => {
      commit('setProgramUIState', {
        action: 'CREATE',
        status: 'SUCCESS'
      })
    }).catch((err) => {
      const errMsg = err.response.data.message
      commit('setProgramUIState', {
        action: 'CREATE',
        status: 'FAILED',
        message: errMsg
      })
    })
  },
  deleteProgram({ commit }, programId) {
    commit('setProgramUIState', {
      action: 'DELETE',
      status: 'LOADING'
    })
    axios.delete(`/program/${programId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then((response) => {
      const message = response.data.message
      commit('setProgramUIState', {
        action: 'DELETE',
        status: 'SUCCESS', 
        message
      })
    }).catch((err) => {
      const errMsg = err.response.data.message
      commit('setProgramUIState', {
        action: 'DELETE',
        status: 'FAILED',
        message: errMsg
      })
    })
  },
  updateProgram({ commit }, { program, programId }) {
    const formData = new FormData()
    formData.append('id', programId)
    formData.append('name', program.name.value)
    formData.append('date', program.date.value)
    formData.append('location', program.location)
    formData.append('needs', JSON.stringify(program.needs))
    formData.append('description', program.description.value)
    formData.append('type', program.type)
    formData.append('limited', program.limited)
    formData.append('isListed', program.isListed ? 1 : 0)
    formData.append('withMerchandise', program.withMerchandise)
    formData.append('price', parseInt(program.price, 10))
    formData.append('shippingCost', parseInt(program.shippingCost, 10))
    if (program.poster !== null) formData.append('poster', program.poster)
    if (!program.eventAddress.isEmpty) formData.append('eventAddress', program.eventAddress.value)
    if (!program.telconLink.isEmpty) formData.append('telconLink', program.telconLink.value)
    if (program.wagLink !== null) formData.append('wagLink', program.wagLink)

    commit('setProgramUIState', {
      action: 'UPDATE',
      status: 'LOADING'
    })
    axios.put('/program', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(() => {
      commit('setProgramUIState', {
        action: 'UPDATE',
        status: 'SUCCESS'
      })
    }).catch((err) => {
      const errMsg = err.response.data.message
      commit('setProgramUIState', {
        action: 'UPDATE',
        status: 'FAILED',
        message: errMsg
      })
    })
  }
}