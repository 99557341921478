import childItem from './needs-child-item/needs-child-item.vue'

export default {
  name: 'parent-item',
  props: {
    number: Number,
    position: Number,
    needs: Object,
  },
  data() {
    return {
      positionChild: -1,
      components: [],
    }
  },
  computed: {
    programUIState() {
      return this.$store.getters['program/programUIState']
    }
  },
  watch: {
    programUIState(state) {
      const { status } = state
      if (status === 'SUCCESS') {
        this.positionChild = -1
        this.components = []
      }
    }
  },
  mounted() {
    console.log(this.needs.items);
    const items = this.needs.items
    items.forEach((_, index) => {
      this.components.push({position: index, view: childItem})
    });
  },
  methods: {
    add() {
      this.positionChild += 1
      this.needs.items.push({name: ''})
      this.components.push({position: this.positionChild, view: childItem})
    },
    deleteChild(position) {
      this.components.splice(position, 1)
      this.needs.items.splice(position, 1)
    }
  }
}