export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
    }
  },
  methods: {
    login() {
      this.isLoading = true
      this.$http.post('/admins/login', { 
        email: this.email,
        password: this.password 
      }).then(request => {
        this.isLoading = false
        const data = request.data.data
        this.$store.dispatch('auth/login', data.accessToken)
        this.$router.push({ name: 'main' })
      }).catch(err => {
        this.isLoading = false
        if (err.response) {
          const statusCode = err.response.status
          if (statusCode == 403) {
            this.$swal.fire({
              title: 'Login Gagal',
              icon: 'error',
              text: 'Email Anda belum diverifikasi, silakan verifikasi email Anda terlebih dahulu.',
            })
          } else {
            const message = err.response.data.message
            this.$toast.error(message)
          }
        } else {
          this.$toast.error('Network Error')
        }
      })
    }
  }
}
