import { toIdr } from '../../../../helper/currency-helper'

export default {
  name: 'booked-program',
  data() {
    return {
      program: {},
      items: [],
      fields: [
        { key: 'no', label: 'No' },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'phoneNumber', label: 'Phone Number', sortable: true },
        { key: 'age', label: 'Age', sortable: true },
        { key: 'registeredAt', label: 'Registered At', sortable: true },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 100], 
      search: null,
    }
  },
  created() {
    this.$store.commit('main/setNavbarTitle', 'Booked Program')
    const programId = this.$route.params.programId
    this.getProgram(programId)
  },
  methods: {
    getProgram(programId) {
      this.$http.get(`/program/${programId}`).then((res) => {
        this.program = res.data.data
        if (this.program.limited === 0) this.getBookedProgram(this.program.id)
        else this.getBookedLimitedProgram(this.program.id)
      }).catch((err) => {
        if (err.response) {
          const message = err.response.data.message
          this.$toast.error(message)
        } else {
          this.$toast.error('Network Error')
        }
      })
    },
    getBookedProgram(programId) {
      this.fields.splice(1, 0, { key: 'bookId', label: 'Book ID', sortable: true })
      this.fields.splice(2, 0, { key: 'userId', label: 'User ID', sortable: true })
      this.$http.get(`/program/registered/${programId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((res) => {
        const data = res.data.data
        let no = 0
        this.items = data.map((d) => {
          d.no = no += 1
          return d
        })
        if (this.program.withMerchandise) this.fields.push({ key: 'actions', label: 'Actions' })
        this.totalRows = data.length;
      }).catch((err) => {
        if (err.response) {
          if (err.response.status !== 404) {
            const message = err.response.data.message
            this.$toast.error(message)
          }
        } else {
          this.$toast.error('Network Error')
        }
      })
    },
    getBookedLimitedProgram(programId) {
      this.fields.splice(1, 0, { key: 'id', label: 'ID', sortable: true })
      this.fields.splice(4, 0, { key: 'profession', label: 'Profession', sortable: true })
      this.fields.splice(5, 0, { key: 'domicile', label: 'Domicile', sortable: true })
      this.fields.splice(6, 0, { key: 'deviceOS', label: 'Device OS', sortable: true })
      this.$http.get(`/limited-program/${programId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((res) => {
        const data = res.data.data
        let no = 0
        this.items = data.map((d) => {
          d.no = no += 1
          return d
        })
        this.totalRows = data.length;
      }).catch((err) => {
        if (err.response) {
          if (err.response.status !== 404) {
            const message = err.response.data.message
            this.$toast.error(message)
          }
        } else {
          this.$toast.error('Network Error')
        }
      })
    },
    exportToExcel() {
      const url = this.program.limited ? `/limited-program/registered/${this.program.id}/export` : `/program/registered/${this.program.id}/export`
      this.$http.get(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((res) => {
        const filename = res.data.data.filename
        const encodedFileName = encodeURIComponent(filename)
        this.$http.get(`/admins/file/${encodedFileName}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          responseType: 'blob',
        }).then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();

          this.$swal.fire({
            title: 'Success',
            icon: 'success',
            text: 'Export success',
          })
        })
      }).catch(() => {
        this.$swal.fire({
          title: 'Failed',
          icon: 'error',
          text: 'Export failed',
        })
      })
    },
    getImagePosterUrl(value) {
      return `${process.env.VUE_APP_BASE_STORAGE_URL}/${value}`
    },
    getEventLink(value) {
      return `${process.env.VUE_APP_BASE_PROGRAM_LINK}/${value}`
    },
    getPriceIdr(value) {
      return value === 0 ? 'Free' : toIdr(value)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}