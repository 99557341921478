import deleteModal from '../..//delete-modal/delete-modal.vue'
import { toIdr } from '../../../helper/currency-helper'
export default {
  name: 'program',
  components: {
    deleteModal
  },
  data() {
    return {
      title: String,
      showModalDelete: false,
      selectedProgramId: '',
      fields: [
        { key: 'id', label: 'Program ID', sortable: true },
        { key: 'name', label: 'Program Name', sortable: true },
        { key: 'date', label: 'Event Date', sortable: true },
        { 
          key: 'price',
          label: 'Price',
          sortable: true,
          formatter: (value) => {
            return value === 0 ? 'Free' : toIdr(value)
          },
        },
        { key: 'visibility', label: 'Visibility', sortable: true },
        { key: 'createdAt', label: 'Created At', sortable: true },
        { key: 'actions', label: 'Actions' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 100], 
      programType: 'All',
      search: null,
    }
  },
  created() {
    this.$store.commit('main/setNavbarTitle', 'Program')
  },
  computed: {
    programUIState() {
      return this.$store.getters['program/programUIState']
    },
    events() {
      const items = this.$store.getters['program/getEvents'].map((e) => {
        e.visibility = e.isListed === 1 ? 'Ditampilkan ke semua pengguna' : 'Tidak ditampilkan ke semua pengguna'
        return e
      })

      this.totalRows = items.length
      return items
    }
  },
  mounted() {
    this.$store.dispatch('program/getPrograms')
  },
  watch: {
    'programType'(type) {
      if (type === 'All') this.$store.dispatch('program/getPrograms')
      else this.$store.dispatch('program/getPrograms', type.toUpperCase())
    },
    programUIState(state) {
      const { action, status, code, message } = state
      switch(status) {
        case 'SUCCESS':
          if (action === 'DELETE') {
            this.$toast.success(message)
            this.$store.dispatch('program/getPrograms', this.programType.toUpperCase() === "ALL" ? null : this.programType.toUpperCase())
          }
          break
        case 'FAILED':
          if (code !== 404) this.$toast.error(message)
          break
      }
    }
  },
  methods: {
    getImagePosterUrl(value) {
      return `${process.env.VUE_APP_BASE_STORAGE_URL}/${value}`
    },
    getEventLink(value) {
      return `${process.env.VUE_APP_BASE_PROGRAM_LINK}/${value}`
    },
    getPriceIdr(value) {
      return value === 0 ? 'Free' : toIdr(value)
    },
    onEditClick(id) {
      this.$router.push({ name: 'edit-program', params: { programId: id } })
    },
    onDeleteClick(program) {
      this.showModalDelete = true
      this.selectedProgramId = program.id
      this.deleteModalMessage = `Apakah Anda yakin akan menghapus Program <b>${program.name}</b>`
    },
    onOpenBookedProgram(id) {
      this.$router.push({ name: 'booked-program', params: { programId: id } })
    },
    onAddProgramClick() {
      this.$router.push({ name: 'create-program' })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}