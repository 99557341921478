export default {
  name: 'add-new-member',
  props: {
    title: String,
    selectedMember: null,
  },
  data() {
    return {
      isLoading: false,
      modalTitle: '',
      _selectedEvent: null,
      member: {
        name: {
          value: '',
          isEmpty: false
        },
        position: {
          value: '',
          isEmpty: false
        },
        igAccount: null,
        profilePicture: {
          value: '',
          isEmpty: false
        },
      }
    }
  },
  created() {
    if (this.selectedMember !== null) {
      this.member.name.value = this.selectedMember.name
      this.member.position.value = this.selectedMember.position
      this.member.igAccount = this.selectedMember.igAccount
    }
  },
  computed: {
    ourteamUIState() {
      return this.$store.getters['ourteam/ourteamUIState']
    }
  },
  watch: {
    ourteamUIState(state) {
      const { status, message } = state
      switch(status) {
        case 'LOADING':
          this.isLoading = true
          break
        case 'SUCCESS':
          this.isLoading = false
          this.$emit('success')
          break
        case 'FAILED':
          this.isLoading = false
          this.$emit('close')
          this.$swal.fire({
            title: 'Failed',
            icon: 'error',
            text: message,
          })
          break
      }
      this.$store.commit('ourteam/setOurteamUIState', 'NONE')
    }
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files || e.dataTransfer.files
      this.member.profilePicture.value = file[0]
    },
    isInputValid() {
      this.member.name.isEmpty = this.member.name.value === ''
      this.member.position.isEmpty = this.member.position.value === ''
      this.member.profilePicture.isEmpty = this.member.profilePicture.value === ''

      if (this.selectedMember === null) {
        if (this.member.name.isEmpty || this.member.position.isEmpty || this.member.profilePicture.isEmpty) {
          return false
        } else return true
      } else {
        if (this.member.name.isEmpty || this.member.position.isEmpty) {
          return false
        } else return true
      }
    },
    saveMember() {
      if (this.isInputValid()) {
        if (this.selectedMember) {
          this.member.id = this.selectedMember.id
          this.$store.dispatch('ourteam/updateMemberInfo', this.member)
        } else this.$store.dispatch('ourteam/addNewMember', this.member)
      } else {
        console.log('invalid input');
      }
    },
    deleteMember() {
      this.$store.dispatch('ourteam/deleteMember', this.selectedMember.id)
    }
  }
}