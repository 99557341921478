export default {
  name: 'message',
  data() {
    return {
      messages: []
    }
  },
  created() {
    this.$store.commit('main/setNavbarTitle', 'Message')
  },
  mounted() {
    this.getMessages();
  },
  methods: {
    getMessages() {
      this.$http.get('/message', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((res) => {
        this.messages = res.data.data
      }).catch((err) => {
        if (err.response) {
          const statusCode = err.response.status
          const message = err.response.data.message
          if (statusCode !== 404) this.$toast.error(message)
        } else this.$toast.error('Network Error')
      })
    }
  }
}