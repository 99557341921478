import parentItem from './needs-parent-item/needs-parent-item.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'create-program',
  components: {
    DatePicker
  },
  data() {
    return {
      from: '',
      isLoading: false,
      position: 0,
      components: [{position: 0, view: parentItem, needs: {name: '', items: []}}],
      program: {
        name: {
          value: '',
          isEmpty: false
        },
        date: {
          value: '',
          isEmpty: false
        },
        location: 'ONSITE',
        eventAddress: {
          value: '',
          isEmpty: false,
        },
        telconLink: {
          value: '',
          isEmpty: false,
        },
        needs: [],
        poster: null,
        wagLink: null,
        type: 'EVENT',
        description: {
          value: '',
          isEmpty: false
        },
        limited: 0,
        isListed: false,
        withMerchandise: 0,
        price: 0,
        shippingCost: 0,
      },
      programId: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => { vm.from = from })
  },
  created() {
    const programId = this.$route.params.programId
    this.programId = programId
    let navbarTitle = 'Buat Program Baru'
    if (this.programId !== undefined && this.programId !== null) navbarTitle = 'Ubah Program'
    this.$store.commit('main/setNavbarTitle', navbarTitle)
  },
  computed: {
    programUIState() {
      return this.$store.getters['program/programUIState']
    }
  },
  watch: {
    programUIState(state) {
      const { action, status, data, message } = state
      switch(status) {
        case 'LOADING':
          this.isLoading = true
          break
        case 'SUCCESS':
          this.isLoading = false
          if (action === 'CREATE') {
            this.position = 0
            this.components = [{position: 0, view: parentItem, needs: {name: '', items: []}}]
            this.program.name.value = ''
            this.program.date.value = ''
            this.program.location = 'ONSITE'
            this.program.eventAddress.value = ''
            this.program.telconLink.value = ''
            this.program.needs.value = []
            this.program.wagLink = null
            this.program.description.value = ''
            this.program.type = 'EVENT'
            this.program.limited = 0
            this.program.price = 0

            this.$swal.fire({
              title: 'Success',
              icon: 'success',
              text: 'Program has been saved!',
            })
            this.$router.push({ name: 'program' })
          } else if (action === 'UPDATE') {
            this.$swal.fire({
              title: 'Success',
              icon: 'success',
              text: 'Program has been updated!',
            })
            this.$router.push({ name: this.from.name })
          } else if (action === 'GETTING') {
            this.program.name.value = data.name
            this.program.date.value = data.date
            this.program.location = data.location
            this.program.eventAddress.value = data.eventAddress
            this.program.telconLink.value = data.telconLink
            this.program.wagLink = data.wagLink
            this.program.description.value = data.description
            this.program.type = data.type
            this.program.limited = data.limited
            this.program.isListed = data.isListed
            this.program.withMerchandise = data.withMerchandise
            this.program.price = data.price
            this.program.shippingCost = data.shippingCost

            this.components = []
            const needs = JSON.parse(data.needs)
            needs.forEach((need) => {
              this.position += 1
              this.components.push({
                position: this.position,
                view: parentItem,
                needs: need
              })
            })
          }
          break
        case 'FAILED':
          this.isLoading = false
          this.$swal.fire({
            title: 'Failed',
            icon: 'error',
            text: message,
          })
          break
      }
      this.$store.commit('program/setProgramUIState', 'NONE')
    },
    'program.location'(newLocation, oldLocation) {
      if ((oldLocation === 'ONSITE_ONLINE' && newLocation === 'ONLINE') || (oldLocation === 'ONSITE' && newLocation === 'ONLINE')) this.program.eventAddress.value = ''
      if ((oldLocation === 'ONSITE_ONLINE' && newLocation === 'ONSITE') || (oldLocation === 'ONLINE' && newLocation === 'ONSITE')) this.program.telconLink.value = ''
    }
  },
  mounted() {
    if (this.programId !== undefined && this.programId !== null) this.$store.dispatch('program/getProgramById', this.programId)
  },
  methods: {
    add() {
      this.position += 1
      this.components.push({position: this.position, view: parentItem, needs: {name: '', items: []}})
    },
    deleteParent(position) {
      this.components.splice(position, 1)
    },
    isInputValid() {
      this.program.name.isEmpty = this.program.name.value === ''
      this.program.date.isEmpty = this.program.date.value === ''
      this.program.eventAddress.isEmpty = this.program.eventAddress.value === ''
      this.program.telconLink.isEmpty = this.program.telconLink.value === ''
      this.program.description.isEmpty = this.program.description.value === ''

      if (this.program.name.isEmpty || this.program.date.isEmpty || this.program.eventAddress.isEmpty || this.program.telconLink.isEmpty || this.program.description.isEmpty) {
        if (this.program.eventAddress.isEmpty || this.program.telconLink.isEmpty) {
          if (this.program.eventAddress.isEmpty && this.program.location === 'ONSITE') return false
          if (this.program.telconLink.isEmpty && this.program.location === 'ONLINE') return false
          if ((this.program.eventAddress.isEmpty || this.program.telconLink.isEmpty) && this.program.location === 'ONSITE_ONLINE') return false

          return true
        } else return false
      } else return true
    },
    onFileChange(e) {
      const file = e.target.files || e.dataTransfer.files
      this.program.poster = file[0]
    },
    save() {
      if (this.isInputValid()) {
        this.components.forEach(component => {
          if (component.needs.name !== '') this.program.needs.push(component.needs)
        });
        
        if (this.programId === null || this.programId === undefined) this.$store.dispatch('program/createProgram', this.program)
        else this.$store.dispatch('program/updateProgram', { program: this.program, programId: this.programId })
      }
    }
  }
}