export default {
  name: 'migrate-users',
  data() {
    return {
      isLoading: false,
      users: [],
    }
  },
  created() {
    this.$store.commit('main/setNavbarTitle', 'Migrasi ke Teman Hannah')
  },
  mounted() {
    this.getUsersReadyToMigrate()
  },
  methods: {
    getUsersReadyToMigrate() {
      this.$http.get('/limited-program/users', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((res) => {
        const result = res.data.data.users
        this.users = result
      }).catch(() => {
        this.users = []
      })
    },

    async migrateUser() {
      this.isLoading = true
      let i = 1
      for (const e of this.users) {
        await this.doMigrate(e, i)
        i += 1
      }
      this.isLoading = false
    },

    doMigrate(e, i) {
      return this.$http.post('/users/migrate', { ...e }).then(() => {
        if (i % 10 == 0) this.getUsersReadyToMigrate()
        if (this.users.length < 10) this.getUsersReadyToMigrate()
      }).catch(err => {
        const message = err.response.data.message
        console.log(message);
      })
    }
  }
}