import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { toIdr } from '../../../helper/currency-helper'

export default {
  name: 'transaction',
  components: {
    DatePicker
  },
  data() {
    return {
      transactions: [],
      fields: [
        { key: 'no', label: 'No' },
        { key: 'bookId', label: 'Registration ID' },
        { key: 'program.name', label: 'Event Name', sortable: true },
        { key: 'user.name', label: 'User Name', sortable: true },
        { key: 'user.email', label: 'Email', sortable: true },
        {
          key: 'total',
          label: 'Total',
          sortable: true,
        },
        { key: 'status', label: 'Payment Status', sortable: true },
        {
          key: 'expiredAt',
          label: 'Expired At',
          sortable: true,
        },
        { key: 'actions', label: 'Actions' }
      ],
      paymentStatusOptions: [
        { name: 'PENDING', selected: false },
        { name: 'PAID', selected: false },
        { name: 'EXPIRED', selected: false }
      ],
      date: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 100],
      filter: null,
      queryParam: {
        status: '',
        startDate: '',
        endDate: ''
      },
      _timer: null
    }
  },
  created() {
    this.$store.commit('main/setNavbarTitle', 'Transaction')
    this.getTransactions()
  },
  methods: {
    getTransactions({
      status = undefined,
      startDate = undefined,
      endDate = undefined
    } = {}) {
      let path = '/transactions'
      const queries = []

      if (status) queries.push(`status=[${status}]`)
      if (startDate && endDate) queries.push(`startDate=${startDate}&endDate=${endDate}`)

      if (queries.length) path = `${path}?${queries.join('&')}`
      this.$http.get(path, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((res) => {
        const data = res.data.data;
        let no = 0
        this.transactions = data.map((transaction) => {
          const trx = transaction
          trx.no = no += 1
          trx.total = toIdr(trx.total)
          trx.expiredAt = moment(trx.expiredAt).utc(true).utcOffset(0).format('YYYY-MM-DD HH:mm')
          return trx
        });
        this.totalRows = this.transactions.length
      }).catch((err) => {
        if (err.response) {
          if (err.response.status !== 404) {
            const message = err.response.data.message
            this.$toast.error(message)
          } else {
            this.transactions = []
          }
        } else {
          this.$toast.error('Network Error')
        }
      })
    },
    onChekedChange() {
      if (this._timer) clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        const selectedPaymentStatus = this.paymentStatusOptions
          .filter(item => item.selected)
          .map(item => `"${item.name}"`)
          .join(",")
        this.queryParam.status = selectedPaymentStatus
        this.getTransactions(this.queryParam)
        this._timer = null
      }, 750)
    },
    onDateChange() {
      if (this._timer) clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.queryParam.startDate = this.date[0]
        this.queryParam.endDate = this.date[1]
        this.getTransactions(this.queryParam)
        this._timer = null
      }, 750)
    },
    setAsPaid(index, transactionId) {
      const status = 'PAID'
      const formData = new FormData()
      formData.append('id', transactionId)
      formData.append('status', status)
      this.$http.put('transaction/status', formData).then(() => {
        this.transactions[index].status = status
      }).catch((err) => {
        let errorMessage = "Network error";
        if (err.response) errorMessage = err.response.data.message
        this.$toast.error(errorMessage)
      });
    },
    exportToExcel() {
      let path = '/transaction/export'
      const {
        status = undefined,
        startDate = undefined,
        endDate = undefined
      } = this.queryParam;
      const queries = []

      if (status) queries.push(`status=[${status}]`)
      if (startDate && endDate) queries.push(`startDate=${startDate}&endDate=${endDate}`)

      if (queries.length) path = `${path}?${queries.join('&')}`

      this.$http.get(path, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((res) => {
        const filename = res.data.data.filename
        this.$http.get(`/admins/file/${filename}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          responseType: 'blob',
        }).then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); //or any other extension
          document.body.appendChild(link);
          link.click();

          this.$swal.fire({
            title: 'Success',
            icon: 'success',
            text: 'Export success',
          })
        })
      }).catch(() => {
        this.$swal.fire({
          title: 'Failed',
          icon: 'error',
          text: 'Export failed',
        })
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}