export default {
  login(context, token) {
    localStorage.setItem('token', token)
    // context.dispatch('getUserId')
    context.commit('setToken', token)
  },
  logout({ commit }) {
    localStorage.removeItem("token")
    commit('setToken', undefined)
  }
  // getUserId(context) {
  //   const userId = localStorage.getItem('id')
  //   if (!userId) return
  //   context.commit('setUserId', userId)
  // }
}