export default {
  name: 'email-verification',
  data() {
    return {
      isSuccess: false,
      title: '',
      message: ''
    }
  },
  created() {
    this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      const activationCode = this.$route.params.activationCode
      this.$http.put(`/admins/verification/${activationCode}`).then(() => {
        this.isSuccess = true
        this.title = 'Verifikasi Email Berhasil'
        this.message = 'Silakan masuk dengan alamat email yang sudah didaftarkan.'
      }).catch((err) => {
        this.isSuccess = false
        if (err.response) {
          this.title = 'Verifikasi Email Gagal'
          this.message = 'Kode verifikasi tidak valid'
        } else {
          this.title = 'Verifikasi Email Gagal'
          this.message = 'Server mengalami gangguan'
        }
      })
    },
    goToLogin() {
      this.$router.push({ name: 'login' })
    }
  }
}
