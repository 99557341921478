import axios from '../../remote/axios/index.js'

export default {
  addNewMember({ commit }, member) {
    const formData = new FormData()
    formData.append('name', member.name.value)
    formData.append('position', member.position.value)
    formData.append('profilePicture', member.profilePicture.value)
    if (member.igAccount !== null && member.igAccount !== "") formData.append('igAccount', member.igAccount)

    commit('setOurteamUIState', {
      action: 'CREATE',
      status: 'LOADING'
    })

    axios.post('/ourteam', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(() => {
      commit('setOurteamUIState', {
        action: 'CREATE',
        status: 'SUCCESS'
      })
    }).catch((err) => {
      const statusCode = err.response.status
      const errMsg = err.response.data.message
      commit('setOurteamUIState', {
        action: 'CREATE',
        status: 'FAILED',
        statusCode,
        message: errMsg
      })
    })
  },
  switchIgStatus({ commit }, payload) {
    axios.put('/ourteam/switch/ig', payload, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(() => {}).catch((err) => {
      const statusCode = err.response.status
      const errMsg = err.response.data.message
      commit('setOurteamUIState', {
        action: 'UPDATE',
        status: 'FAILED',
        statusCode,
        message: errMsg
      })
    })
  },
  updateMemberInfo({ dispatch, commit }, member) {
    commit('setOurteamUIState', {
      action: 'UPDATE',
      status: 'LOADING'
    })

    const payload = {
      id: member.id,
      name: member.name.value,
      position: member.position.value,
    }

    if (member.igAccount !== null && member.igAccount !== "") payload.igAccount = member.igAccount
    else dispatch('switchIgStatus', { id: member.id, status: 0 })

    axios.put('/ourteam', payload, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(() => {
      commit('setOurteamUIState', {
        action: 'UPDATE',
        status: 'SUCCESS'
      })
    }).catch((err) => {
      const statusCode = err.response.status
      const errMsg = err.response.data.message
      commit('setOurteamUIState', {
        action: 'UPDATE',
        status: 'FAILED',
        statusCode,
        message: errMsg
      })
    })
  },
  deleteMember({ commit }, id) {
    commit('setOurteamUIState', {
      action: 'DELETE',
      status: 'LOADING'
    })
    
    axios.delete(`/ourteam/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(() => {
      commit('setOurteamUIState', {
        action: 'DELETE',
        status: 'SUCCESS'
      })
    }).catch((err) => {
      const statusCode = err.response.status
      const errMsg = err.response.data.message
      commit('setOurteamUIState', {
        action: 'UPDATE',
        status: 'FAILED',
        statusCode,
        message: errMsg
      })
    })
  }
}