export default {
  name: 'delete-modal',
  props: {
    modalTitle: String,
    programId: Number,
    message: String
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    programUIState() {
      return this.$store.getters['program/programUIState']
    }
  },
  watch: {
    programUIState(state) {
      const { status } = state
      switch(status) {
        case 'LOADING':
          this.isLoading = true
          break
        case 'SUCCESS':
          this.isLoading = false
          this.$emit('close')
          break
        case 'FAILED':
          this.isLoading = false
          this.$emit('close')
          break
      }
    }
  },
  methods: {
    deleteProgram() {
      this.$store.dispatch('program/deleteProgram', this.programId)
    }
  }
}