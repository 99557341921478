import member from './member/member.vue'
import addNewMember from './add-new-member/add-new-member.vue'

export default {
  name: 'team-hannah',
  components: {
    member,
    addNewMember
  },
  data() {
    return {
      newMemberModal: {
        showModal: false,
        title: '',
        selectedMember: null
      },
      selectedTab: 1,
      founders: [],
      teams: [],
    }
  },
  created() {
    this.$store.commit('main/setNavbarTitle', 'Tim Hannah')
  },
  mounted() {
    this.getTeamMembers()
  },
  methods: {
    slideTo(tab) {
      this.selectedTab = (tab === 'tab-founder') ? 1 : 2
      document.getElementById(tab).scrollIntoView();
    },
    getTeamMembers() {
      this.$http.get(`/ourteam`).then((res) => {
        const data = res.data.data.map((e) => {
          e.profilePicture = `${process.env.VUE_APP_BASE_STORAGE_URL}/ourteam/${e.profilePicture}`
          return e
        })

        this.founders = data.filter((e) => (e.position === 'Founder'))
        this.teams = data.filter((e) => (e.position !== 'Founder'))
      }).catch((err) => {
        const statusCode = err.response.status
        const message = err.response.data.message
        if (statusCode === 404) {
          this.founders = []
          this.teams = []
        } else this.$toast.error(message)
      })
    },
    addNewMember() {
      this.newMemberModal.showModal = true
      this.newMemberModal.title = 'Add New Member'
      this.newMemberModal.selectedMember = null
    },
    selectMember(member) {
      this.newMemberModal.showModal = true
      this.newMemberModal.title = 'Edit Member'
      this.newMemberModal.selectedMember = member
    },
    onSuccessAddUpdateMember() {
      this.newMemberModal.showModal = false
      this.newMemberModal.selectedMember = null
      this.getTeamMembers()
    }
  }
}