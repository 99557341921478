import Vue from 'vue'
import App from './App.vue'
import VueAxios from 'vue-axios'
import Toast from 'vue-toastification'
import router from './router.js'
import axios from './remote/axios'
import store from './store/index.js'
import { BootstrapVue } from 'bootstrap-vue'
import SweetAlertIcons from 'vue-sweetalert-icons';
import VueSweetalert2 from 'vue-sweetalert2';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-toastification/dist/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './main.css'

Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(SweetAlertIcons);

const toasOptions = {
  timeout: 5000,
};

Vue.use(Toast, toasOptions);

const swalOption = {
  confirmButtonColor: '#007bff',
  cancelButtonColor: '#dc3545',
};

Vue.use(VueSweetalert2, swalOption);

Vue.config.productionTip = false

new Vue({
  router,
  axios,
  store,
  render: h => h(App),
}).$mount('#app')


// import Vue from 'vue'
// import App from './App.vue'

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
