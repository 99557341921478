export default {
  name: 'member',
  props: {
    member: Object
  },
  data() {
    return {
      igStatus: false,
    }
  },
  watch: {
    'member.igStatus'(newStatus, oldStatus) {
      if (oldStatus !== undefined) {
        this.switchIgStatus(newStatus ? 1 : 0)
      }
    }
  },
  methods: {
    switchIgStatus(status) {
      this.$store.dispatch('ourteam/switchIgStatus', { id: this.member.id, status })
    }
  }
}